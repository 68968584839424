import { toApiDateTimeFormat } from "services/date.service";
import * as moment from "moment";

const refundDateTimeRequest = {
  refundedDateFrom: {
    transform: ({ src }) => {
      if (!src?.dateRange?.[0]) return toApiDateTimeFormat(moment().startOf("day"));
      return toApiDateTimeFormat(src?.dateRange?.[0]);
    },
  },
  refundedDateTo: {
    transform: ({ src }) => {
      if (!src?.dateRange?.[1]) return toApiDateTimeFormat(moment().endOf("day"));
      return toApiDateTimeFormat(src?.dateRange?.[1], true);
    },
  },
};

const filterRequest = {
  venueId: { key: "venueId" },
  page: { key: "page" },
  pageSize: { key: "pageSize" },
  searchKey: { key: "searchKey" },
  status: { key: "status" },
};

export const refundClaimssReportRequest = {
  ...filterRequest,
  ...refundDateTimeRequest,
};
