import { formatNumberToMoneyWithCurrencySymbol } from "services/money.service";
import {
  dateTimeRequest,
  filterRequest
} from "./common.mapper";

export const guestRefundsReportRequest = {
  ...filterRequest,
  ...dateTimeRequest
};

export const guestRefundsReportResponse = {
  guestEmailOrPhoneNumber: {
    key: "guestEmailOrPhoneNumber",
  },
  tagUid: { key: "tagUid" },
  tagBalance: {
    transform: ({ src }) => {
      return formatNumberToMoneyWithCurrencySymbol(src.tagBalance);
    },
  }
};
