import { Get, Post } from "services";
import { ApiPath } from "paths";

export const searchRefundClaimsReport = async (body) => {
  const res = await Get(`${ApiPath.REFUND_TRANSACTIONS(body.venueId)}`, body);
  return res;
};

export const searchRefundClaimsReportExport = async (body) => {
  const res = await Post(`${ApiPath.EXPORT_REFUND_TRANSACTIONS}`, body);
  return res;
};
