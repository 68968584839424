import lang from "translations";

export const RefundClaimsReportColumns = [
  {
    key: "firstName",
    text: lang.firstName,
  },
  {
    key: "lastName",
    text: lang.lastName,
  },
  {
    key:"phoneNumber",
    text:lang.phoneNo
  },
  {
    key:"email",
    text:lang.email
  },
  {
    key: "tagUid",
    text: lang.tagUid,
  },
  {
    key: "refundedFeeAmount",
    text: lang.mdrCharges,
  },
  {
    key:"requestAmount",
    text: lang.amountClaimed
  },
  {
    key:"refundedDate",
    text: lang.claimDate
  },
  {
    key: "status",
    text: lang.status
  }
  
];
