import lang from "translations";

export const GuestRefundReportColumns = [
  {
    key: "guestEmailOrPhoneNumber",
    text: lang.guestId,
  },
  {
    key: "tagUid",
    text: lang.tagUid,
  },
  {
    key: "tagBalance",
    text: lang.tagBalance,
  },
];
