import { Post } from "services";
import { ApiPath } from "paths";

export const searchGuestRefundsReport = async (body) => {
  const res = await Post(`${ApiPath.SEARCH_GUEST_REFUND}`, body);
  return res;
};

export const searchGuestRefundsReportExport = async (body) => {
  const res = await Post(`${ApiPath.EXPORT_GUEST_REFUND}`, body);
  return res;
};
