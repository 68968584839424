import moment from "moment";

export const refundClaimReportFilterState = (venueId) => {
  return {
    venueId,
    page: 1,
    pageSize: 20,
    dateRange: [moment("00:00:00", "HH:mm:ss"), moment("23:59:00", "HH:mm:ss")],
    status: "REFUNDED",
    searchKey: ""
  };
};
