import { DateTime } from "enums";
import { filterRequest } from "mappers";
import moment from "moment";
import { Path } from "paths";
import {
  addQueryParam,
  formatDate,
  formatNumberToMoneyWithCurrencySymbol,
  formatNumberWithComma,
  sortByKeyName,
  subtract,
} from "services";
import lang from "translations";

export const endOfDayListResponse = {
  id: {
    key: "eodReportId",
  },
  // shifts: {
  //   transform: ({ src }) => {
  //     return (
  //       src.shifts?.filter(
  //         (shift) =>
  //           !shift.ignoredInEod &&
  //           shift.eodReportId != null &&
  //           shift.eodReportId === src.eodReportId
  //       )?.length || 0
  //     );
  //   },
  // },
  // totalTransactions: {
  //   transform: ({ src }) => {
  //     let totalTransaction = 0;
  //     if (src.shifts) {
  //       src.shifts
  //         .filter(
  //           (shift) =>
  //             !shift.ignoredInEod &&
  //             shift.eodReportId != null &&
  //             shift.eodReportId === src.eodReportId
  //         )
  //         ?.forEach((shift) => {
  //           if (shift.closeDate) {
  //             totalTransaction = totalTransaction + shift.totalTransactions;
  //           }
  //         });
  //     }
  //     return totalTransaction;
  //   },
  // },
  shifts : { key : "totalShifts"},
  totalTransactions : {key:"totalTransactions"},
  totalSales : {key:"totalSales"},
  businessDay: {
    transform: ({ src }) => {
      // let notIgnoredShifts =
      //   src.shifts
      //     .filter((shift) => !shift.ignoredInEod)
      //     .sort((a, b) => {
      //       return new Date(b.openDate) - new Date(a.openDate);
      //     }) || [];
      // const lastShift = notIgnoredShifts[notIgnoredShifts.length - 1] || {};
      // const businessDaysCount = getDateDifference(
      //   notIgnoredShifts[0]?.openDate,
      //   lastShift.closeDate ? lastShift.closeDate : lastShift.openDate
      // );

      // if (!src.businessDays) {
      //   return formatDate(src.startDateTime, DateTime.C);
      // }
      // if (
      //   (src.businessDays && src.businessDays.length > 1) ||
      //   businessDaysCount > 1 ||
      //   moment(src.endDateTime).isAfter(src.startDateTime, "day")
      // ) {
      //   return "Multiple";
      // }
      return formatDate(src.businessDays[0], DateTime.C);
    },
  },
  dateStarted: {
    transform: ({ src }) => {
      return src.startDateTime ? formatDate(src.startDateTime, DateTime.C) : "-";
    },
  },
  timeStarted: {
    transform: ({ src }) => {
      return src.startDateTime ? formatDate(src.startDateTime, DateTime.B) : "-";
    },
  },
  dateEnded: {
    transform: ({ src }) => {
      return src.endDateTime ? formatDate(src.endDateTime, DateTime.C) : "-";
    },
  },
  timeEnded: {
    transform: ({ src }) => {
      return src.endDateTime ? formatDate(src.endDateTime, DateTime.B) : "-";
    },
  },
  status: { key: "status" },
  transactionSynced: {
    transform: ({ src }) => {
      let transactionSynced = false;
      if (src.shifts) {
        src.shifts.map((shift) => {
          return (transactionSynced = shift.transactionSynced);
        });
      }
      return transactionSynced;
    },
  },
};

export const endOfDayListFilterRequest = {
  ...filterRequest,
};

export const endOfDayResponse = {
  _keys: [
    "totalTransactions",
    "totalLocations",
    "notes",
    "devices",
    "eodReportId",
    "totalReturnCredits",
    "totalTopUp",
    "totalCreditsKept",
    "totalCreditsFromVenues",
  ],
  totalSales: {
    transform: ({ src }) => {
      return src.totalSales >= 0 ? formatNumberToMoneyWithCurrencySymbol(src.totalSales) : "-";
    },
  },
  shifts: {
    transform: ({ src }) => {
      return src.shifts.sort(sortByKeyName("openDate")).reverse();
    },
  },
  averageAmountPerItem: {
    transform: ({ src }) => {
      return src.averageAmountPerItem >= 0
        ? formatNumberToMoneyWithCurrencySymbol(src.totalTransactions)
        : "-";
    },
  },
  totalShifts: {
    transform: ({ src }) => {
      return src.shifts?.length;
    },
  },
  totalOpenShifts: {
    transform: ({ src }) => {
      let openShifts = 0;
      src.shifts?.forEach((shift) => {
        if (!shift.closeDate) {
          openShifts = openShifts + 1;
        }
      });
      return openShifts;
    },
  },
  totalIgnoredShifts: {
    transform: ({ src }) => {
      let ignoredShifts = 0;
      src.shifts?.forEach((shift) => {
        if (shift.ignoredInEod) {
          ignoredShifts = ignoredShifts + 1;
        }
      });
      return ignoredShifts;
    },
  },
  totalOpenAndNotIgnoredShifts: {
    transform: ({ src }) => {
      let total = 0;
      src.shifts?.forEach((shift) => {
        if (!shift.closeDate && !shift.ignoredInEod) {
          total = total + 1;
        }
      });
      return total;
    },
  },
  totalDevices: {
    transform: ({ src }) => {
      return src.devices?.length;
    },
  },
  allTransactionSynced: {
    transform: ({ src }) => {
      let shiftTransactionsSync = [];
      src.shifts.map((shift) => {
        return shiftTransactionsSync.push(shift.transactionSynced);
      });
      // console.log("a", shiftTransactionsSync);
      // console.log(shiftTransactionsSync.includes(false));
      if (shiftTransactionsSync.includes(false)) {
        return false;
      } else {
        return true;
      }
    },
  },
  salesBreakdown: {
    transform: ({ src }) => {
      let formattedBreakdown = [];

      src.shifts?.forEach((shift) => {
        console.log(shift);
        if (shift.salesBreakdown) {
          let paymentMethodBreakdown = JSON.parse(shift.salesBreakdown);

          if (paymentMethodBreakdown) {
            paymentMethodBreakdown.forEach((paymentMethod) => {
              let added = false;

              formattedBreakdown.forEach((formatted) => {
                if (paymentMethod.id === formatted.id) {
                  formatted.amount += paymentMethod.amount;
                  added = true;
                }
              });

              if (!added) {
                formattedBreakdown.push(paymentMethod);
              }
            });
          }
        }
      });

      if (!formattedBreakdown || formattedBreakdown.length === 0) {
        return null;
      } else {
        return formattedBreakdown;
      }
    },
  },
  topUpBreakdown: {
    transform: ({ src }) => {
      let formattedBreakdown = [];

      src.shifts?.forEach((shift) => {
        if (shift.topUpBreakdown) {
          let paymentMethodBreakdown = JSON.parse(shift.topUpBreakdown);

          if (paymentMethodBreakdown) {
            paymentMethodBreakdown.forEach((paymentMethod) => {
              let added = false;

              formattedBreakdown.forEach((formatted) => {
                if (paymentMethod.id === formatted.id) {
                  formatted.amount += paymentMethod.amount;
                  added = true;
                }
              });

              if (!added) {
                formattedBreakdown.push(paymentMethod);
              }
            });
          }
        }
      });

      if (!formattedBreakdown || formattedBreakdown.length === 0) {
        return null;
      } else {
        return formattedBreakdown;
      }
    },
  },
  voidSalesBreakdown: {
    transform: ({ src }) => {
      let formattedBreakdown = [];

      src.shifts?.forEach((shift) => {
        if (shift.voidSalesBreakdown) {
          let paymentMethodBreakdown = JSON.parse(shift.voidSalesBreakdown);

          if (paymentMethodBreakdown) {
            paymentMethodBreakdown.forEach((paymentMethod) => {
              let added = false;

              formattedBreakdown.forEach((formatted) => {
                if (paymentMethod.id === formatted.id) {
                  formatted.amount += paymentMethod.amount;
                  added = true;
                }
              });

              if (!added) {
                formattedBreakdown.push(paymentMethod);
              }
            });
          }
        }
      });

      if (!formattedBreakdown || formattedBreakdown.length === 0) {
        return null;
      } else {
        return formattedBreakdown;
      }
    },
  },
};

export const viewEndOfDayReport = {
  _keys: ["notes", "type", "totalTips"],
  dayStart: {
    transform: ({ src }) => {
      return moment(src.startDateTime).format(DateTime.K);
    },
  },
  dayEnd: {
    transform: ({ src }) => {
      return src.endDateTime ? moment(src.endDateTime).format(DateTime.K) : "";
    },
  },
  shiftDate: {
    transform: ({ src }) => {
      return `${moment(src.startDateTime).format(DateTime.K)} ${
        src.endDateTime ? ` - ${moment(src.endDateTime).format(DateTime.K)}` : ""
      }`.trim();
    },
  },
  isOpen: {
    transform: ({ src }) => {
      return !Boolean(src.endDateTime);
    },
  },
  shifts: {
    transform: ({ src }) => {
      if (src.shifts?.length) {
        const normalize = {};
        const ignored = {};
        src.shifts.forEach(
          ({
            shiftId,
            location,
            openDate,
            closeDate,
            staffProfile = {},
            ignoredInEod,
            eodReportId,
            closeBalance,
            expectedCloseBalance,
          }) => {
            const dayStart = moment(openDate).format("MMM DD, YYYY");
            const obj = {
              id: shiftId,
              shiftId: `#${shiftId}`,
              name: staffProfile?.fullName || "-",
              location: location?.locationName || location?.locationId || "-",
              shift: closeDate
                ? `${moment(openDate).format("hh:mm A")} ${` - ${moment(closeDate).format(
                    "hh:mm A"
                  )}`}`.trim()
                : lang.opened,
              dayStart,
              dayValue: moment(openDate).format("X"),
              closeBalance,
              expectedCloseBalance,
            };
            if (ignoredInEod || !eodReportId) {
              ignored[dayStart] = ignored[dayStart]
                ? [...ignored[dayStart], obj].sort(sortByKeyName("dayValue")).reverse()
                : [obj];
            } else {
              normalize[dayStart] = normalize[dayStart]
                ? [...normalize[dayStart], obj].sort(sortByKeyName("dayValue")).reverse()
                : [obj];
            }
          }
        );

        return {
          shift: Object.keys(normalize)
            .reverse()
            .map((key) => {
              return {
                date: key,
                dateValue: moment(key, "MMM DD, YYYY").format("X"),
                items: normalize[key],
              };
            })
            .sort(sortByKeyName("dateValue"))
            .reverse(),
          ignored: Object.keys(ignored)
            .reverse()
            .map((key) => {
              return {
                date: key,
                dateValue: moment(key, "MMM DD, YYYY").format("X"),
                items: ignored[key],
              };
            })
            .sort(sortByKeyName("dateValue"))
            .reverse(),
        };
      }
      return { ignored: [], shift: [] };
    },
  },
  devices: {
    transform: ({ src }) => {
      if (src.devices?.length) {
        return src.devices
          .map(
            ({
              deviceName,
              deviceSerialNumber,
              deviceLogUuid,
              deviceImei,
              deviceMacAddress,
              deviceId,
            }) => {
              return {
                deviceName: deviceName || "",
                deviceImei:
                  deviceSerialNumber ||
                  deviceLogUuid ||
                  deviceImei ||
                  deviceMacAddress ||
                  `#${deviceId}`,
              };
            }
          )
          .sort(sortByKeyName("deviceName"));
      }
      return [];
    },
  },
  summary: {
    transform: ({ src }) => {
      const { totalSales = 0, shifts, additionalChargeResponses } = src || {};
      let totalShiftsIgnore = 0;

      const shiftTotals = {
        totalCreditsUsed: 0,
        totalFreeCreditsUsed: 0,
        totalPostpaidUsed: 0,
        totalSaleCash: 0,
        totalSaleCard: 0,
        totalSaleOthers: 0,
        totalTax: 0,
        totalTopup: 0,
        totalTopupCash: 0,
        totalTopupCredit: 0,
        totalTopupOther: 0,
        totalRefunded: 0,
        totalTransactions: 0,
        totalVoidedItems: 0,
        totalVoidValue: 0,
        totalVoidValuePouchPay: 0,
        totalVoidValueCash: 0,
        totalVoidValueCard: 0,
        totalVoidValueOthers: 0,
        totalDiscount: 0,
        totalFreeCreditsIssued: 0,
        totalFreeCreditsRemoved: 0,
        totalVouchersIssued: 0,
        totalVouchersRemoved: 0,
        totalVouchersRedeemed: 0,
        totalVouchersRedeemedSupplyValue: 0,
        totalVouchersRedeemedRetailValue: 0,
        totalGuestsCheckedIn: 0,
        totalGuestsCheckedOut: 0,
        totalIssuedTag: 0,
        totalReturnedTag: 0,
        totalClearedTag: 0,
        openBalance: 0,
        expectedCloseBalance: 0,
        closeBalance: 0,
        totalCreditsKept: 0,
        totalCreditsFromVenues: 0,
      };

      src.shifts.forEach((shift) => {
        if (shift.ignoredInEod) totalShiftsIgnore++;
        Object.keys(shiftTotals).forEach((key) => {
          if (shift && !shift.ignoredInEod && shift.eodReportId) {
            shiftTotals[key] = shiftTotals[key] + shift[key];
          }
        });
      });

      const discrepancy = subtract(shiftTotals.closeBalance, shiftTotals.expectedCloseBalance);

      const obj = {
        hasTransactions: Boolean(shiftTotals.totalTransactions),
        hasDiscrepancy: Boolean(discrepancy),
      };
      obj.additionalCharges = additionalChargeResponses;
      [
        ["totalTransactions"],
        ["totalShifts", shifts.length],
        ["totalVoidedItems"],
        ["totalVouchersIssued"],
        ["totalVouchersRemoved"],
        ["totalVouchersRedeemed"],
        ["totalGuestsCheckedIn"],
        ["totalGuestsCheckedOut"],
        ["totalIssuedTag"],
        ["totalReturnedTag"],
        ["totalClearedTag"],
      ].forEach(([key, value]) => {
        if (Object.keys(shiftTotals).includes(key)) {
          obj[key] = formatNumberWithComma(shiftTotals[key]);
        } else if (value) {
          obj[key] = formatNumberWithComma(value);
        }
      });

      [
        ["totalSales", totalSales],
        ["totalFreeCreditsUsed"],
        ["totalPostpaidUsed"],
        ["totalSaleCash"],
        ["totalSaleCard"],
        ["totalSaleOthers"],
        ["totalCreditsUsed"],
        ["totalTax"],
        ["totalCreditsUsed"],
        ["totalTopup"],
        ["totalTopupCash"],
        ["totalTopupCredit"],
        ["totalTopupOther"],
        ["totalRefunded"],
        ["totalVoidValue"],
        ["totalVoidValuePouchPay"],
        ["totalVoidValueCash"],
        ["totalVoidValueCard"],
        ["totalVoidValueOthers"],
        ["totalDiscount"],
        ["totalFreeCreditsIssued"],
        ["totalFreeCreditsRemoved"],
        ["totalVouchersRedeemedSupplyValue"],
        ["totalVouchersRedeemedRetailValue"],
        ["openBalance"],
        ["expectedCloseBalance"],
        ["discrepancy", discrepancy],
        ["closeBalance"],
        ["totalCreditsKept"],
        ["totalCreditsFromVenues"],
      ].forEach(([key, value]) => {
        if (Object.keys(shiftTotals).includes(key)) {
          obj[key] = formatNumberToMoneyWithCurrencySymbol(shiftTotals[key]);
        } else if (value != null) {
          obj[key] = formatNumberToMoneyWithCurrencySymbol(value);
        }
      });
      return { ...obj, totalShiftsIgnore };
    },
  },
  redirectUrl: {
    transform: ({ src }) => {
      const { startDateTime, endDateTime } = src || {};
      const sd = startDateTime?.replaceAll("+", encodeURIComponent("+"));
      const ed = endDateTime?.replaceAll("+", encodeURIComponent("+"));
      return addQueryParam(Path.TRANSACTION, {
        _startDateTime: sd,
        _endDateTime: endDateTime
          ? ed
          : moment().format()?.replaceAll("+", encodeURIComponent("+")),
        shifts: src.shifts
          .filter((shift) => !shift.ignoredInEod)
          .map((shift) => shift.shiftId)
          .join(","),
      });
    },
  },
  date: {
    transform: ({ src }) => {
      return src?.businessDays?.length
        ? moment(src?.businessDays?.[0], "YYYY-MM-DD").format(DateTime.C)
        : "--";
    },
  },
  reportingDay: {
    transform: ({ src }) => {
      return src?.businessDays?.length
        ? moment(src.businessDays[0], "YYYY-MM-DD").format("DD MMM YYYY")
        : "--";
    },
  },
  endedBy: {
    transform: ({ src }) => {
      return src.endedBy?.fullName;
    },
  },
  salesBreakdown: {
    transform: ({ src }) => {
      let formattedBreakdown = [];

      src.shifts?.forEach((shift) => {
        if (!shift.ignoredInEod && shift.salesBreakdown) {
          let paymentMethodBreakdown = JSON.parse(shift.salesBreakdown);

          if (paymentMethodBreakdown) {
            paymentMethodBreakdown.forEach((paymentMethod) => {
              let added = false;

              formattedBreakdown.forEach((formatted) => {
                if (paymentMethod.id === formatted.id) {
                  formatted.amount += paymentMethod.amount;
                  added = true;
                }
              });

              if (!added) {
                formattedBreakdown.push(paymentMethod);
              }
            });
          }
        }
      });

      if (!formattedBreakdown || formattedBreakdown.length === 0) {
        return null;
      } else {
        return formattedBreakdown;
      }
    },
  },
  topUpBreakdown: {
    transform: ({ src }) => {
      let formattedBreakdown = [];

      src.shifts?.forEach((shift) => {
        if (!shift.ignoredInEod && shift.topUpBreakdown) {
          let paymentMethodBreakdown = JSON.parse(shift.topUpBreakdown);

          if (paymentMethodBreakdown) {
            paymentMethodBreakdown.forEach((paymentMethod) => {
              let added = false;

              formattedBreakdown.forEach((formatted) => {
                if (paymentMethod.id === formatted.id) {
                  formatted.amount += paymentMethod.amount;
                  added = true;
                }
              });

              if (!added) {
                formattedBreakdown.push(paymentMethod);
              }
            });
          }
        }
      });

      if (!formattedBreakdown || formattedBreakdown.length === 0) {
        return null;
      } else {
        return formattedBreakdown;
      }
    },
  },
  voidSalesBreakdown: {
    transform: ({ src }) => {
      let formattedBreakdown = [];

      src.shifts?.forEach((shift) => {
        if (!shift.ignoredInEod && shift.voidSalesBreakdown) {
          let paymentMethodBreakdown = JSON.parse(shift.voidSalesBreakdown);
          if (paymentMethodBreakdown) {
            paymentMethodBreakdown.forEach((paymentMethod) => {
              let added = false;

              formattedBreakdown.forEach((formatted) => {
                if (paymentMethod.id === formatted.id) {
                  formatted.amount += paymentMethod.amount;
                  added = true;
                }
              });

              if (!added) {
                formattedBreakdown.push(paymentMethod);
              }
            });
          }
        }
      });

      if (!formattedBreakdown || formattedBreakdown.length === 0) {
        return null;
      } else {
        return formattedBreakdown;
      }
    },
  },
};
